<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-3">Detalhes da campanha</h1>
    <div class="px-3 py-3 border w-2/5">
      <h3 class="text-base font-medium my-1">ID: {{ campanha && campanha._id ? campanha._id : ''}}</h3>
      <h3 class="text-base font-medium my-1">Campanha: {{ campanha && campanha.nome ? campanha.nome : ''}}</h3>
      <h3 class="text-base font-medium my-1">Total: {{ campanha && campanha.total ? campanha.total : ''}}</h3>
      <h3 class="text-base font-medium my-1 text-green-800">Enviados: {{ campanha && campanha.totalEnviado ? campanha.totalEnviado : 0 }}</h3>
      <h3 class="text-base font-medium my-1 text-red-800">Falhas: {{ campanha && campanha.totalFalhas ? campanha.totalFalhas : 0}}</h3>
      <h3 class="text-base font-medium my-1">Login: {{ campanha && campanha.login && campanha.login.nome ? campanha.login.nome : ''}}{{ campanha && campanha.login && campanha.login.email ? ` - ${campanha.login.email}` : ''}}</h3>
    </div>
    <label for="editVariaveis" class="my-2 block text-sm font-medium text-gray-700">
      <input
        type="checkbox"
        v-model="falhas"
        class="rounded-sm"
        id="editVariaveis"
      />
      <span class="ml-2"> Visualizar apenas falhas </span>
    </label>
    <span v-if="lista && lista.length" class="block text-sm font-medium py-1"><span class="text-blue-500">{{ lista.length }}</span> / {{ total }} </span>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-2 py-1 text-left text-xs font-medium text-gray-500"
                  >
                    IDX
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Telefone
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ $store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') === -1 ? 'Mensagem' : 'Mensagem / Template'   }}
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    COD Cliente
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data agendada
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data disparada
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white divide-y divide-gray-200"
              >
                <tr v-for="(item, idx) in lista" :key="item._id">
                  <td class="px-2 py-1">
                    {{ idx + 1 }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    {{ item.telefone }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap" v-if="!item.template" :title="item.mensagem">
                    {{
                      item.mensagem
                        ? item.mensagem.length > 40
                          ? `${item.mensagem.slice(0, 40)}...`
                          : item.mensagem
                        : ""
                    }}
                    <a
                      class="flex"
                      v-if="item.arquivo && item.arquivo.filename"
                      target="_blank"
                      className="click-icon"
                      :href="`${api}/upload?mimetype=${item.arquivo.mimetype}&filename=${item.arquivo.filename}&folder=arquivosWhats`"
                    >
                      <svg
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>Arquivo</span>
                    </a>
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap" v-else>
                    {{
                      item.template ? item.template.nome : ""
                    }}
                    <a
                      class="flex"
                      v-if="item.arquivo && item.arquivo.filename"
                      target="_blank"
                      className="click-icon"
                      :href="`${api}/upload?mimetype=${item.arquivo.mimetype}&filename=${item.arquivo.filename}&folder=arquivosWhats`"
                    >
                      <svg
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span>Arquivo</span>
                    </a>
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    {{ item.idCustom ? item.idCustom : "" }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    {{ moment(item.dataHoraDisparo).format("DD/MM/YYYY HH:mm:ss") }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap">
                    <span v-if="item.data">
                      {{ moment(item.data).format("DD/MM/YYYY HH:mm:ss") }}
                    </span>
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap" v-if="$store.state.user.acessos_lzv2 && $store.state.user.acessos_lzv2.indexOf('whatsappApiOficial') === -1 && !$store.state.user.neon">
                    {{ item.result && item.temzap && item.enviou ? "Enviado para fila de disparo" : "" }}
                    {{ item.bloqueado ? 'Bloqueado' : item.result && (!item.temzap || !item.enviou) ? "Falhou" : "" }}
                    {{ !item.result ? "Programada" : "" }}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap" v-else>
                    {{ item.status ? traduzDlr(item.status) : 'PENDENTE' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <span v-if="lista && lista.length" class="block text-sm font-medium py-2"><span class="text-blue-500">{{ lista.length }}</span> / {{ total }} <span v-if="lista.length < total" @click="addLimit" class="ml-3 underline text-green-500 cursor-pointer">Carregar mais...</span></span>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      limit: this.$route.query && this.$route.query.api === 'true' ? 20 : 2000,
      falhas: false,
      skip: 0,
      total: 0,
      breadcrumb: [
        { url: "/campanhas", value: "Campanhas" },
        {
          url: `/campanhas/detalhes/${this.$route.params.id}`,
          value: "Detalhes",
        },
      ],
      route: "campanhas",
      campanha: null,
      moment: moment,
      lista: [],
    };
  },
  methods: {
    async addLimit(){
      if(this.$route.query && this.$route.query.api === 'true') {
        this.limit += 20;
        this.start();
        return;
      }
      if(this.lista.length + 2000 > this.total) this.limit += this.total - this.lista.length;
      else this.limit += 2000;
      
      await this.start();
    },
    traduzDlr(status) {
      if (status === -1) return 'PENDENTE';
      if (status === 0) return 'NÚMERO INVALIDO';
      if (status === 1) return 'ENVIADO';
      if (status === 3) return 'ENTREGUE';
      if (status === 4) return 'VISUALIZADO';
      if (status === 9) return 'NÃO ENVIADO';
      return 'PENDENTE';
    },
    async start() {
      const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
      if (id) {
          const req = await this.$http.get(`/v1/campanhasLZV2/${id}/contatos?limit=${this.limit}&skip=${this.skip}&falhas=${this.falhas}`);
          this.campanha = req.data.campanha;
          this.lista = req.data.data;
          this.total = req.data.total;
      }
    },
  },
  beforeMount() {

    this.$watch('falhas', async (desativada) => {
      const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : '';
      if(!id) return;

      const req = await this.$http.get(`/v1/campanhasLZV2/${id}/contatos?limit=${this.limit}&skip=${this.skip}&falhas=${desativada}`);
      this.campanha = req.data.campanha;
      this.lista = req.data.data;
      this.total = req.data.total;
    });

    this.start();
  }
};
</script>
