<template>
  <div id="app">
    <Navbar
      class="col-span-12 md:col-span-12 xl:col-span-12"
      v-if="$store.state.isLogged"
    />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  components: {
    Navbar,
  },
  methods: {
    async start() {
      if (!this.$store.state.user || !this.$store.state.user._id) return;
      const userReq = await this.$http.post("/v1/users/meLZV2");

      if(!userReq.data || !userReq.data.ativo){

        if (this.store) {
          this.store.commit("logout");
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }

        return this.$router.push({ path: "/login" }).catch((err) => {
          if(err){
            window.location.replace("/login");
          }
        });        
      } 

      localStorage.setItem("user", JSON.stringify(userReq.data));
      this.$store.commit("login", userReq.data);
      this.$store.commit('setConfig', { colorPrincipal: userReq.data.colorPrincipal, logo: userReq.data.logo });

      if(userReq.data.operador && userReq.data.admin) {
        return this.$router.push({ path: "/" });
      }

      if(userReq.data.operador && userReq.data.visualizarChat) {
        return this.$router.push({ path: "/chats" });
      }

      if(userReq.data.operador && !userReq.data.chatAcesso) {
        return this.$router.push({ path: "/campanhas" });
      }

      if (userReq.data.operador) {
        return this.$router.push({ path: "/chats" });
      }
      
      this.sockets.subscribe(`logoutToken-${this.$store.state.user._id}`, async function (token) {
        if (localStorage.getItem("token") && localStorage.getItem("token").toString() === token.toString()) {
          await this.$http.post("/v1/operador/sessao/logout", { id: this.$store.state.user._id});
          if (this.store) {
            this.store.commit("logout");
          }
          this.$router.push({ path: "/login" }).catch(() => {});
        }
      });
      
      return this.$router.push({ path: "/" });

    },
  },
  async mounted() {
    this.start();
  },
  beforeDestroy() {
    if(!this.$store.state.user || !this.$store.state.user._id) return;
    this.sockets.unsubscribe(`logoutToken-${this.$store.state.user._id}`);
  },
};
</script>

<style scoped>
>>> {
  --vs-controls-color: #6B7280;
  --vs-controls-size: 0.7;
  --vs-actions-padding: 4px 11px 0 3px;
}
</style>
